import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from 'react-images';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// my custom css:
import './carouselalice.css';

const handleDragStart = e => e.preventDefault();

class CarouselAlice extends React.Component {
  constructor() {
    super();

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };
  }

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };
  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };
  gotoImage = index => {
    this.setState({
      currentImage: index,
    });
  };
  handleClickImage = () => {
    if (this.state.currentImage === this.props.items.length - 1) return;

    this.gotoNext();
  };

  render() {
    const { items } = this.props;

    if (!items) return null;

    const responsiveList = {
      0: { items: 1 },
      568: { items: 2 },
      1024: { items: 4 },
      1600: { items: 5 },
    };

    const gallery = items.map((obj, i) => {
      return (
        <div className="item">
          <a
            onClick={e => this.openLightbox(i, e)}
            className="portfolio-box"
            href={obj.src}
          >
            <img
              className="img-fluid"
              src={obj.thumbnail}
              onDragStart={handleDragStart}
              alt="Action Squad"
            />
          </a>
        </div>
      );
    });

    return (
      <>
        <section id="screenshots" className="container-fluid p-0">
          <div className="row no-gutters">
            <AliceCarousel
              mouseTracking
              infinite
              disableDotsControls
              disableButtonsControls
              paddingLeft={0}
              paddingRight={0}
              autoPlay
              autoPlayInterval={2000}
              autoPlayStrategy={'default'}
              items={gallery}
              responsive={responsiveList}
            />
          </div>
        </section>

        <Lightbox
          currentImage={this.state.currentImage}
          images={this.props.items.map(img => {
            img.caption = ''; //`${img.title} - ${img.desc}`;
            return img;
          })}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
        />
      </>
    );
  }
}

CarouselAlice.displayName = 'Carousel';
CarouselAlice.propTypes = {
  images: PropTypes.array,
};

export default CarouselAlice;
