import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

//import config from '../../config';
import Footer from '../components/Footer';
import Header from '../components/Header';
import CarouselAlice from '../components/carouselalice';
import ReactPlayer from 'react-player';
//images
import img_gameLogo540 from '../assets/img/actionsquad_540x300.png';
import img_psh_white from '../assets/img/pixelshard_white_tr.png';
import img_khg_white from '../assets/img/killhouse_white_tr.png';
import img_crosshairs from '../assets/icons/crosshairs.png';
import img_shell from '../assets/icons/shell.png';
import img_trailerposter from '../assets/images/trailerposter.jpg';
//svg
import AngleDoubleDownSvg from '../assets/icons/angle-double-down.svg';
import { Link } from 'gatsby';

const platform_logos = [
  {
    src: require('../assets/img/platforms/small/steam.png'),
    title: 'Steam',
    url: 'https://store.steampowered.com/app/686200/Door_Kickers_Action_Squad/',
  },
  {
    src: require('../assets/img/platforms/small/nintendo.png'),
    title: 'Nintendo',
    url:
      'https://www.nintendo.com/games/detail/door-kickers-action-squad-switch/',
  },
  {
    src: require('../assets/img/platforms/small/xbox.png'),
    title: 'XBox',
    url:
      'https://www.microsoft.com/en-us/p/door-kickers-action-squad/9nq7xb6hsmkf',
  },
  {
    src: require('../assets/img/platforms/small/playstation.png'),
    title: 'Playstation',
    url:
      'https://store.playstation.com/en-us/product/UP3250-CUSA15037_00-DOORKICKERS0SIEA',
  },
  {
    src: require('../assets/img/platforms/small/ios.png'),
    title: 'iOS',
    url: 'https://apps.apple.com/us/app/door-kickers-action-squad/id1497887150',
  },
  {
    src: require('../assets/img/platforms/small/android.png'),
    title: 'Android',
    url:
      'https://play.google.com/store/apps/details?id=com.khg.actionsquad&hl=en',
  },
];

const items_list = [
  {
    src: require('../assets/images/portfolio/fullsize/scr1.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr1.jpg'),
  },
  {
    src: require('../assets/images/portfolio/fullsize/scr2.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr2.jpg'),
  },
  {
    src: require('../assets/images/portfolio/fullsize/scr3.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr3.jpg'),
  },
  {
    src: require('../assets/images/portfolio/fullsize/scr4.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr4.jpg'),
  },
  {
    src: require('../assets/images/portfolio/fullsize/scr5.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr5.jpg'),
  },
  {
    src: require('../assets/images/portfolio/fullsize/scr6.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr6.jpg'),
  },
  {
    src: require('../assets/images/portfolio/fullsize/scr7.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr7.jpg'),
  },
  {
    src: require('../assets/images/portfolio/fullsize/scr8.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/scr8.jpg'),
  },
];

const IndexPage = () => (
  <Layout>
    <Header />

    <section id="home">
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-10 align-self-end">
              <div className="centerlogo">
                <img src={img_gameLogo540} alt="Door Kickers: Action Squad" />
              </div>
              <h1 className="text-white font-weight-bold">
                Available on Steam, Consoles and Mobile!
              </h1>
              <hr className="divider my-4" />
            </div>
            <div className="col-lg-8 align-self-baseline">
              <p className="text-white font-weight-bold mb-5">
                Rescue hostages, disarm bombs and save the day in this old
                school, action side-scroller with retro graphics and retro
                sensibilities.
                <br></br>Cooperative multiplayer included.
              </p>
              <Scroll type="id" element="about">
                <a
                  className="btn btn-primary btn-xl js-scroll-trigger"
                  href="#about"
                >
                  Find Out More
                </a>
              </Scroll>
            </div>
          </div>
        </div>
      </header>
    </section>

    <section className="page-section bg-secondary" id="about">
      <div className="container">
        <div className="text-white text-center mt-0">
          <img
            alt="More"
            src={AngleDoubleDownSvg}
            className="bounce-up-down mb-4"
            style={{ width: 48, height: 48 }}
          />
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center px-1 py-1 bg-primary">
            <div className="video-wrapper">
              <div className="video-content">
                <ReactPlayer
                  wrapper="div"
                  width="100%"
                  height="100%"
                  url="https://www.youtube-nocookie.com/embed/fZkvkOnR-Aw?modestbranding=1"
                  light={img_trailerposter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="console_text" className="bg-primary">
      <div className="container bg-primary">
        <div className="row justify-content-center">
          <div className="col-lg-8 align-self-baseline">
            <div className="text-white text-center mt-0">
              <h2 className="text-white font-weight-bold pt-3">
                Available on all major platforms
              </h2>
              <hr className="divider light my-4" />

              <p className="text-white font-weight-light mb-4">
                <b>Door Kickers: Action Squad</b> is a crazy old-school side
                scroller action game that puts you in the role of a SWAT trooper
                and sends you to deal with the bad guys in Nowhere City USA.
                <br></br>
                <b>Available now on your PC, console and smartphone!</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="console_logos" className="bg-primary">
      <div className="container bg-primary py-3">
        <div className="row justify-content-center">
          {platform_logos.map(logo => (
            <div key={logo.title} className="col-lg-2 col-md-6 text-center">
              <div className="p-1">
                <a
                  href={logo.url}
                  target="_blank"
                  title={logo.title}
                  rel="noopener noreferrer"
                >
                  <img className="logo-image" src={logo.src} alt={logo.title} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="page-section bg-secondary" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <div className="row justify-content-center">
              <iframe
                title="Buy Action Squad on Steam"
                src="https://store.steampowered.com/widget/686200/196428/"
                width="646"
                height="190"
                frameBorder="0"
                align="center"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="screenshots_container">
      <CarouselAlice items={items_list} />
    </section>

    <section className="page-section py-0" id="sellingpoints">
      <div className="staticbg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 text-left">
              <ul className="service-list-ul">
                <li className="service-list">
                  <img src={img_crosshairs} alt="" />
                  <p className="text-white font-weight-light">
                    High detail retro graphics
                  </p>
                </li>
                <li className="service-list">
                  <img src={img_crosshairs} className="text-white" alt="" />
                  <p className="text-white font-weight-light">
                    6 playable characters
                  </p>
                </li>
                <li className="service-list">
                  <img src={img_crosshairs} className="text-white" alt="" />
                  <p className="text-white font-weight-light">
                    Unique Strategic Abilities system
                  </p>
                </li>
                <li className="service-list">
                  <img src={img_crosshairs} className="text-white" alt="" />
                  <p className="text-white font-weight-light">
                    More than 80 non linear levels
                  </p>
                </li>
                <li className="service-list">
                  <img src={img_crosshairs} className="text-white" alt="" />
                  <p className="text-white font-weight-light">
                    Over 40 weapons and gear items
                  </p>
                </li>
                <li className="service-list">
                  <img src={img_crosshairs} className="text-white" alt="" />
                  <p className="text-white font-weight-light">
                    20+ enemy types and Bosses
                  </p>
                </li>
                <li className="service-list">
                  <img src={img_shell} className="text-white" alt="" />
                  <p className="text-white font-weight-light">
                    Couch and Online Coop Multiplayer
                  </p>
                </li>
                <li className="service-list">
                  <img src={img_shell} className="text-white" alt="" />
                  <p className="text-white font-weight-light">
                    Available on all consoles and phones
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-left">
              <p className="text-white font-weight-light mb-0">
                Choose your gear, then kick the doors down and face the action.
                Adapt and react by the seat of your pants, and if needed –
                restart without a care and rethink. Master weapons recoil and
                time your reloads, use distance and cover to your advantage and
                use the in-game Strategic Abilities to decide for yourself when
                healthpacks or gear refills are needed, or if that team-mate of
                yours is worth saving. Or just save up the Points earned and
                unleash your Ultimate to waltz through that tricky final room.
                <br></br>
                <br></br>And when it gets too hard, bring over a friend to help.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section py-0" id="publishers">
      <div className="staticbg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
              <a
                href="http://pixelshard.com"
                target="_blank"
                title="Pixel Shard"
                rel="noopener noreferrer"
              >
                <img
                  className="logo-image-scaler"
                  src={img_psh_white}
                  alt="PixelShard"
                />
              </a>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
              <a
                href="http://inthekillhouse.com/actionsquad/"
                target="_blank"
                title="KillHouse Games"
                rel="noopener noreferrer"
              >
                <img
                  className="logo-image-scaler"
                  src={img_khg_white}
                  alt="KillHouse Games"
                />
              </a>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="align-items-center">
              <Link
                to="/credits"
                className="btn btn-primary btn-l js-scroll-trigger"
              >
                CREDITS
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
